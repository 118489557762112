<template>
    <div>
        <app-header />
          <main>
            <section
              v-if="Object.keys(pricing).length"
              id="pricing-plan"
            >
              <!-- title text and switch button -->
              <div class="text-center">
                <h1 class="mt-5">
                  Pricing Plans
                </h1>
                <p class="mb-2 pb-75">
                  All plans include the ability to book time with an expert/customer.
                </p>
                <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
                  <!-- <h6 class="mr-1 mb-0">
                    Monthly
                  </h6>
                  <b-form-checkbox
                    id="priceSwitch"
                    v-model="status"
                    name="price-switch"
                    value="annually"
                    unchecked-value="monthly"
                    switch
                    @input="tooglePlan"
                  />
                  <h6 class="ml-50 mb-0">
                    Annually
                  </h6> -->
                </div>
              </div>
              <!--/ title text and switch button -->

              <!-- pricing plan cards -->
              <b-row class="pricing-card">
                <b-col
                  offset-sm-2
                  sm="12"
                  md="12"
                  offset-lg="2"
                  lg="10"
                  class="mx-auto"
                >
                  <b-row>
                    <b-col lg="2" md="0">
                    </b-col>
                    <b-col md="6" lg="4">
                      <b-card
                        align="center"
                      >
                        <!-- img -->
                        <b-img
                          v-if="pricing.basicPlan.img"
                          :src="pricing.basicPlan.img"
                          class="mb-2"
                          alt="basic svg img"
                        />
                        <!--/ img -->
                        <h3>{{ pricing.basicPlan.title }}</h3>
                        <b-card-text>
                          {{ pricing.basicPlan.subtitle }}
                        </b-card-text>

                        <!-- annual plan 
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                            <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.basicPlan.monthlyPrice:pricing.basicPlan.yearlyPlan.perMonth }}</span>
                            <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                          </div>
                          <small
                            v-show="!monthlyPlanShow"
                            class="annual-pricing text-muted"
                          >USD {{ pricing.basicPlan.yearlyPlan.totalAnual }} / year</small>
                        </div>
                         annual plan -->

                        <!-- plan benefit -->
                        <b-list-group class="list-group-circle text-left">
                          <b-list-group-item
                            v-for="(data,index) in pricing.basicPlan.planBenefits"
                            :key="index"
                          >
                            {{ data }}
                          </b-list-group-item>
                        </b-list-group>
                        <!--/ plan benefit -->

                        <!-- buttons -->
                        <b-button v-if="profile && profile.sessionID"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          block
                          class="mt-2"
                          variant="outline-primary"
                          disabled
                        >
                         Downgrade plan
                        </b-button>
                        <b-button v-else
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          block
                          class="mt-2"
                          variant="outline-success"
                        >
                          Selected plan
                        </b-button>
                      </b-card>
                    </b-col>
                    <b-col md="6" lg="4">
                      <b-card
                        class="popular"
                        align="center"
                      >
                        <div
                          v-show="pricing.standardPlan.popular"
                          class="pricing-badge text-right"
                        >
                          <b-badge
                            variant="light-primary"
                            pill
                          >
                            Popular
                          </b-badge>
                        </div>
                        <!-- img -->
                        <b-img
                          v-if="pricing.standardPlan.img"
                          :src="pricing.standardPlan.img"
                          class="mb-1"
                          alt="svg img"
                        />
                        <!--/ img -->
                        <h3>{{ pricing.standardPlan.title }}</h3>
                        <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

                        <!-- annual plan -->
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                            <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.standardPlan.monthlyPrice:pricing.standardPlan.yearlyPlan.perMonth }}</span>
                            <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                          </div>
                          <small
                            v-show="!monthlyPlanShow"
                            class="annual-pricing text-muted"
                          >USD {{ pricing.standardPlan.yearlyPlan.totalAnual }} / year</small>
                        </div>
                        <!--/ annual plan -->

                        <!-- plan benefit -->
                        <b-list-group class="list-group-circle text-left">
                          <b-list-group-item
                            v-for="(data,index) in pricing.standardPlan.planBenefits"
                            :key="index"
                          >
                            {{ data }}
                          </b-list-group-item>
                        </b-list-group>
                        <!--/ plan benefit -->

                        <!-- buttons -->
                        <b-overlay
                          :show="busy"
                          rounded
                          opacity="0.4"
                          spinner-small
                          spinner-variant="primary"
                        >
                         <b-button v-if="profile && profile.sessionID"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            block
                            class="mt-2"
                            variant="outline-success"
                          >
                          Selected plan
                         </b-button>
                          <b-button v-else
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            block
                            class="mt-2"
                            variant="primary"
                            @click="showWaitList()"
                          >
                            Join Waitlist
                          </b-button>
                        </b-overlay>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!--/ pricing plan cards -->

              <!-- pricing free trial -->
              <!-- <div class="pricing-free-trial">
                <b-row>
                  <b-col
                    lg="10"
                    offset-lg="3"
                    class="mx-auto"
                  >
                    <div class="pricing-trial-content d-flex justify-content-between">
                      <div class="text-center text-md-left mt-3">
                        <h3 class="text-primary">
                          Still not convinced? Start with a 14-day FREE trial!
                        </h3>
                        <h5>You will get full access to with all the features for 14 days.</h5>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mt-2 mt-lg-3"
                        >
                          Start 14-day FREE trial
                        </b-button>
                      </div>

                      <b-img
                        fluid
                        :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                        class="pricing-trial-img"
                        alt="svg img"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div> -->
              <!--/ pricing free trial -->

              <!-- pricing faq -->
              <!-- <div class="pricing-faq mt-5">
                <h3 class="text-center">
                  FAQ's
                </h3>
                <p class="text-center">
                  Let us help answer the most common questions.
                </p>
                <b-row class="py-2">
                  <b-col
                    lg="10"
                    offset-lg="2"
                    class="mx-auto"
                  >
                    <app-collapse
                      accordion
                      type="margin"
                    >
                      <app-collapse-item
                        v-for="(data,index) in pricing.qandA"
                        :key="index"
                        :title="data.question"
                      >
                        {{ data.ans }}
                      </app-collapse-item>
                    </app-collapse>
                  </b-col>
                </b-row>
              </div> -->
            </section>
            <!-- Modal -->
            <div class="modal fade" id="waitlistModal" tabindex="-1" role="dialog" aria-labelledby="waitlistModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="waitlistModalSucessTitle">Join the Waitlist</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeWaitList">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                          <form id="wait-list-form" @submit="checkForm">
                                <p v-if="errors.length">
                                    <b class="text-danger">Please correct the following error(s):</b>
                                    <ul>
                                    <li v-for="error in errors" :key="error" class="text-danger">{{ error }}</li>
                                    </ul>
                                </p>

                                <b-form-group
                                    label="Full Name"
                                    label-for="Fullname"
                                    >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="full name"
                                        rules="required"
                                    >
                                        <b-form-input
                                        id="fullname"
                                        v-model="name"
                                        :state="errors.length > 0 ? false:null"
                                        name="register-fullname"
                                        placeholder="john doe"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group
                                    label="Email"
                                    label-for="email"
                                    >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="email"
                                        rules="required|email"
                                    >
                                        <b-form-input
                                        id="email"
                                        v-model="email"
                                        :state="errors.length > 0 ? false:null"
                                        name="register-email"
                                        placeholder="john@example.com"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group
                                    label="I'm interested in:"
                                    label-for="expert"
                                    >
                                    <select class="form-control" v-model="expert" id="expert">
                                        <option>Sharing my expertise</option>
                                        <option>Finding experts or customers</option>
                                        <option>Both</option>
                                    </select>
                                </b-form-group>

                                <b-form-group
                                    label-for="description"
                                    class="mb-4"
                                    >
                                    <textarea class="form-control form-control-alternative" v-model="description" id="description" name="description" rows="4"
                                                cols="80" placeholder="Tell us what your looking for"></textarea>
                                </b-form-group>

                                <input type="submit" class="btn btn-block btn-primary btn-lg" round value="Join">
                                </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="waitlistModalSucess" tabindex="-1" role="dialog" aria-labelledby="waitlistModalSucess" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="waitlistModalSucessTitle">Welcome to the Waitlist</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Welcome to our waitlist. We are excited you've shown interest in our community. We will notify you by email once we have availability. Thanks again, the Hustleforge Team!
                        </div>
                    </div>
                </div>
            </div>
          </main>
        <app-footer />
    </div>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BOverlay, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppFooter from './AppFooter.vue'
import AppHeader from './AppHeader.vue'
import Ripple from 'vue-ripple-directive'
import { getAuth } from "firebase/auth"
import { getFirestore, getDoc, doc, collection, addDoc} from 'firebase/firestore'
import { getFunctions, httpsCallable } from "firebase/functions"

const auth = getAuth()
const db = getFirestore()
const functions = getFunctions()


/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BOverlay,
    AppCollapseItem,
    AppCollapse,
    AppHeader,
    AppFooter,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea, 
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      busy: false,
      profile: null,
      errors: [],
      name: null,
      email: null,
      expert: null,
      description: null,
            
      // validation rules
      required,
      email,
    }
  },
  async mounted() {

    this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
    if(auth.currentUser){
      const profileRes = await getDoc(doc(db, "profiles", auth.currentUser.displayName))
      if(!profileRes.exists()) return
      this.profile = profileRes.data()
    }

    // Get the modal
    var modal = document.getElementById('waitlistModal');
    var closeModal = document.getElementById('waitlistModalSucess');

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
          document.getElementById("waitlistModal").style.display = "none"
          document.getElementById("waitlistModal").classList.remove("show")
      }
      if (event.target == closeModal) {
          document.getElementById("waitlistModalSucess").style.display = "none"
          document.getElementById("waitlistModalSucess").classList.remove("show")
      }
    }
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    closeWaitList(){
      document.getElementById("waitlistModal").style.display = "none"
      document.getElementById("waitlistModal").classList.add("hide")
    },
    showWaitList(){
      document.getElementById("waitlistModal").style.display = "block"
      document.getElementById("waitlistModal").classList.add("show")
    },
    async addwaitlist(){
      try {
          const docRef = await addDoc(collection(db, "waitlist"), {
              name: this.name,
              email: this.email,
              type: this.expert,
              description: this.description,
              time: new Date(),
          });
          document.getElementById("waitlistModal").style.display = "none"
          document.getElementById("waitlistModal").classList.remove("show")
          document.getElementById("waitlistModalSucess").style.display = "block"
          document.getElementById("waitlistModalSucess").classList.add("show")
      } catch (e) {
          console.error("Error adding document: ", e);
      }

      this.name= ''
      this.email = ''
      this.expert = ''
      this.description = ''
    },
    async handleClick(productID) {
      this.busy = true;
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession')
      const stripeResponse = await createCheckoutSession({ priceID: productID })
      if(stripeResponse && stripeResponse.data && stripeResponse.data.url){
        window.open(stripeResponse.data.url, '_self')
      }
      this.busy = false;
    },
    checkForm: function (e) {
      e.preventDefault();

      if (this.name && this.email && this.description) {
        this.addwaitlist();
      }

      this.errors = [];
      if (!this.name) this.errors.push('Name required.');
      if (!this.email) this.errors.push('Email required.');
      if (!this.description) this.errors.push('Tell us what your looking for');
    },
  }
}
/* eslint-disable global-require */
/*@click="handleClick(pricing.standardPlan.stripeProductID)"*/
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
